<template>
  <div class="mobile_tab_bar">
    <div class="tab_bar_content">
      <van-popover
        v-model="showPopoverWechatPublic"
        class="tab_bar_item"
        trigger="click"
        placement="top-start"
      >
        <div class="popover_content">
          <img src="../assets/images/qrcode.jpg" />
          <p>扫一扫关注微信公众号</p>
        </div>
        <template #reference>
          <div class="tab_bar_item">
            <div class="item_icon wechat_public_account"></div>
            <div class="item_title">微信公众号</div>
          </div>
        </template>
      </van-popover>
      <van-popover
        v-model="showPopoverWechat"
        class="tab_bar_item"
        trigger="click"
        placement="top"
      >
        <div class="popover_content">
          <img src="../assets/images/wechat_manager_qrcode.png" />
          <p>扫一扫咨询专属客户经理</p>
        </div>
        <template #reference>
          <div class="tab_bar_item">
            <div class="item_icon wechat_consult"></div>
            <div class="item_title">微信咨询</div>
          </div>
        </template>
      </van-popover>
      <div class="tab_bar_item" @click="showPopup = true">
        <div class="item_icon phone_consult"></div>
        <div class="item_title">电话咨询</div>
      </div>
      <div class="tab_bar_item" @click="showSheet = !showSheet">
        <div class="item_icon go_door"></div>
        <div class="item_title">预约上门</div>
      </div>
    </div>

    <information-sheet :show.sync="showSheet" />

    <van-action-sheet
        v-model="showPopup"
        cancel-text="取消"
        description="热线服务（24小时）"
        close-on-click-action
    >
        <div class="tel-action-popup">
            <a href="tel:4008-166-100">4008-166-100</a>
        </div>
        <div class="tel-action-popup">
            <a href="tel:17302638460">17302638460</a>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Popover from 'vant/lib/popover';
import 'vant/lib/popover/style';

import ActionSheet from 'vant/lib/action-sheet';
import 'vant/lib/action-sheet/style';

import informationSheet from './informationSheet.vue';

export default {
  components: {
    VanPopover: Popover,
    VanActionSheet: ActionSheet,
    informationSheet,
  },
  data() {
    return {
      showPopoverWechatPublic: false,
      showPopoverWechat: false,
      showSheet: false,
      showPopup: false,
    };
  },
  methods: {
    showPopover(type, ev) {
      console.log(type, ev);
    },
  },
};
</script>

<style lang="less" scoped>
.mobile_tab_bar {
  display: none;
}

@media screen and (max-width: 1000PX) {
  .mobile_tab_bar {
    display: block;
    z-index: 4;
    position: fixed;
    width: 100vw;
    bottom: 5.333vw;

    .tab_bar_content {
      width: calc(100% - 3.2vw);
      height: 14.667vw;
      margin: auto;
      background-color: #fff;
      -webkit-box-shadow: 0px -0.533vw 0.533vw 1px rgba(164, 164, 164, 0.12);
      box-shadow: 0px -0.533vw 0.533vw 1px rgba(164, 164, 164, 0.12);
      border-radius: 0.533vw;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .tab_bar_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex: 1 1;

        .item_icon {
          height: 5.867vw;
          width: 5.867vw;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .item_title {
          display: flex;
          justify-content: center;
          font-size: 2.667vw;
          margin-top: 1.067vw;
        }

        .wechat_public_account {
          background-image: url(../assets/images/wechat.svg);
        }

        .wechat_consult {
          background-image: url(../assets/images/wechat_consult.svg);
        }

        .phone_consult {
          background-image: url(../assets/images/phone.svg);
        }

        .go_door {
          background-image: url(../assets/images/go_door.svg);
        }

        /* &:active {
          .wechat_public_account {
            background-image: url(../assets/images/wechat_hover.png);
          }
          .wechat_consult {
            background-image: url(../assets/images/wechat_hover.png);
          }
          .phone_consult {
            background-image: url(../assets/images/phone_hover.png);
          }
          .go_door {
            background-image: url(../assets/images/go_door_hover.png);
          }
        } */
      }
    }
  }
}
</style>

<style lang="less">
@media screen and (max-width: 1000PX) {
  #app {
    margin-bottom: 20vw;
  }
  .popover_content {
    text-align: center;
    padding: 4vw;
    & > img {
      width: 32vw;
      height: 32vw;
    }
    & > p {
      color: #7e8795;
      font-size: 3.2vw;
      margin-top: 1.333vw;
    }
  }
  .tel-action-popup {
    width: 100%;
    font-size: 24px;
    border-bottom: 1px solid #eeeeee;
    & > a {
        font-size: 32px;
        text-align: center;
        display: block;
        background-color: #ffffff;
        padding: 2.4vw;
    }
  }
}
</style>
