import { render, staticRenderFns } from "./tabBar.vue?vue&type=template&id=0b28e5a2&scoped=true"
import script from "./tabBar.vue?vue&type=script&lang=js"
export * from "./tabBar.vue?vue&type=script&lang=js"
import style0 from "./tabBar.vue?vue&type=style&index=0&id=0b28e5a2&prod&lang=less&scoped=true"
import style1 from "./tabBar.vue?vue&type=style&index=1&id=0b28e5a2&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_t5tpsi34mnjnv3nahbqq7a6rfq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b28e5a2",
  null
  
)

export default component.exports