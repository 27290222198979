<template>
    <div class="information_sheet" v-if="isShow" @click.self="isShow = false">
        <div class="box">
            <div class="form" v-if="!showFeedback">
                <div class="title">留下您的信息，允许我们联系您！</div>
                <div class="label required">姓名</div>
                <input type="text" :class="`${submited && !this.form.name ? 'error' : ''}`" v-model.trim="form.name"
                       @focus="focusInput"/>
                <div class="errow_text">
                    {{ submited && !this.form.name ? '请输入您的姓名' : '' }}
                </div>
                <div class="label required">公司全称</div>
                <input type="text" :class="`${submited && !this.form.company ? 'error' : ''}`"
                       v-model.trim="form.company" @focus="focusInput"/>
                <div class="errow_text">
                    {{ submited && !this.form.company ? '请输入公司全称' : '' }}
                </div>
                <div class="label">职位</div>
                <input type="text" v-model.trim="form.job"/>
                <div class="errow_text"></div>
                <div class="label">公司人员规模</div>
                <input type="text" v-model.trim="form.number"/>
                <div class="errow_text"></div>
                <div class="label required">电话</div>
                <input type="text" :class="`${submited && !this.form.phone ? 'error' : ''}`" v-model.trim="form.phone"
                       @focus="focusInput"/>
                <div class="errow_text">
                    {{ submited && !this.form.phone ? '请输入电话' : '' }}
                </div>
                <div class="label">备注</div>
                <textarea
                    type="text"
                    placeholder="可联系您的注意事项，如时间或者方式"
                    v-model.trim="form.remark"
                />
                <div class="btn" @click="submit">提交</div>
                <div class="tips">可拨打 4008-166-1000 转1售前热线</div>
            </div>
            <div class="feedback" v-if="showFeedback">
                <div class="title">已成功收到您的请求信息！</div>
                <div class="tips">
                    请求编号：{{ feedbackNumber }}，我们将在2个工作日内与您取得联系！感谢您的支持与理解
                </div>
                <div class="btn" @click="handleClose">关闭</div>
                <div class="tips">可拨打 4008-166-1000 转1售前热线</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            submited: false,
            form: {
                name: '',
                company: '',
                job: '',
                number: '100',
                phone: '',
                remark: '',
            },
            showFeedback: false,
            feedbackNumber: '',
        };
    },
    methods: {
        async postCustomerInfo() {
            const res = await axios.post(
                'https://console.app.ttkol.club/backend/rl/300004',
                {
                    action: 'postCustomerInfo',
                    name: this.form.name,
                    companyName: this.form.company,
                    jobName: this.form.job,
                    phone: this.form.phone,
                    personnelSize: this.form.number,
                    remark: this.form.remark,
                },
            );
            return res.data;
        },
        submit() {
            this.submited = true;
            if (this.form.name && this.form.company && this.form.phone) {
                this.postCustomerInfo().then((data) => {
                    this.feedbackNumber = data.number;
                    this.showFeedback = true;
                    // 提交之后清除本地存储
                    localStorage.clear('__chiefsoftware:themes_to_save');
                });
            }
        },
        handleClose() {
            this.isShow = false;
            location.reload();//刷新页面
        },
        focusInput() {
            this.submited = false;
        },
    },
    computed: {
        isShow: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('update:show', val);
            },
        },
    },
};
</script>

<style scoped lang="less">
.information_sheet {
    width: 100vw;
    height: 100vh;
    background: #00000066;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        background: #fff;
        padding: 32PX;
        border: 1PX solid #e6ebf2;
        border-radius: 4PX;
        width: 480PX;
        box-sizing: border-box;

        .title {
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 24PX;
            color: #121933;
            padding-bottom: 20PX;
        }

        .label {
            font-family: PingFangSC-Regular;
            font-size: 14PX;
            color: #707481;
            line-height: 26PX;
        }

        .error {
            border: 1PX solid red;
        }

        .required {
            position: relative;
        }

        .required::after {
            content: "*";
            color: red;
            position: absolute;
            left: -8PX;
            top: 0;
        }

        .btn {
            width: 416PX;
            height: 40PX;
            line-height: 40PX;
            background: #5169fe;
            border-radius: 4PX;
            text-align: center;
            font-size: 14PX;
            color: #fff;
            font-family: PingFangSC-Regular;
            margin: 32PX 0 12PX;
            cursor: pointer;
        }

        .errow_text {
            font-family: PingFangSC-Regular;
            font-size: 12PX;
            color: #ff3640;
            line-height: 20PX;
            height: 20PX;
        }

        .tips {
            font-family: PingFangSC-Regular;
            font-size: 14PX;
            color: #707481;
            line-height: 22PX;
            text-align: center;
        }
    }

    .feedback {
        text-align: center;
    }
}

@media screen and (max-width: 1000PX) {
    input,
    textarea {
        margin-top: 10px;
        width: 600px;
    }

    .information_sheet {
        width: 100vw;
        height: 100vh;
        background: #00000066;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999999;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;

        .box {
            background: #fff;
            padding: 40px 30px;
            border: 1px solid #e6ebf2;
            border-radius: 4px;
            width: 660px;
            box-sizing: border-box;
            font-family: PingFangSC-Medium;

            .title {
                font-weight: 500;
                font-size: 24px;
                color: #121933;
                padding-bottom: 20px;
            }

            .label {
                color: #707481;
                line-height: 26px;
            }

            .error {
                border: 1px solid red;
            }

            .required {
                position: relative;
            }

            .required::after {
                content: "*";
                color: red;
                position: absolute;
                left: -8px;
                top: 0;
            }

            .btn {
                width: 600px;
                height: 80px;
                line-height: 80px;
                background: #5169fe;
                border-radius: 4px;
                text-align: center;
                color: #fff;
                margin: 32px 0 20px;
                cursor: pointer;
            }

            .errow_text {
                font-size: 12px;
                color: #ff3640;
                line-height: 20px;
                height: 20px;
            }

            .tips {
                font-size: 14px;
                color: #707481;
                line-height: 22px;
                text-align: center;
            }
        }

        .feedback {
            text-align: center;
        }
    }
}
</style>
