import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'ttkolclub' },
    component: () => import('@/components/main'),
    meta: {
      hideInMenu: true,
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/theme',
        name: 'theme',
        component: () => import('@/views/theme')
      },
      {
        path: '/hall',
        name: 'hall',
        component: () => import('@/views/hall')
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/views/purchase')
      },
      {
        path: '/fixDetail',
        name: 'fixDetail',
        component: () => import('@/views/purchase/fixDetail')
      },
      {
        path: '/freeDetail',
        name: 'freeDetail',
        component: () => import('@/views/purchase/freeDetail')
      },
      {
        path: '/customizeDetail',
        name: 'customizeDetail',
        component: () => import('@/views/purchase/customizeDetail')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact')
      },
      {
        path: '/placeOrder',
        name: 'placeOrder',
        redirect: { name: 'confirmOrder' },
        component: () => import('@/views/placeOrder'),
        children: [
          {
            path: '/placeOrder/confirmOrder',
            name: 'confirmOrder',
            component: () => import('@/views/placeOrder/confirmOrder')
          },
          {
            path: '/placeOrder/payment',
            name: 'payment',
            component: () => import('@/views/placeOrder/payment')
          },
          {
            path: '/placeOrder/buySuccess',
            name: 'buySuccess',
            component: () => import('@/views/placeOrder/buySuccess')
          },
        ]
      },
      {
        path: '/advertise',
        name: 'advertise',
        component: () => import('@/views/advertise/advert')
      },
      {
        path: '/market/tt',
        name: 'exhibition',
        component: () => import('@/views/exhibition'),
        redirect: { name: 'exhibitionList' },
        children: [
          {
            path: '',
            name: 'exhibitionList',
            component: () => import('@/views/exhibition/list'),
          },
          {
            path: ':id',
            name: 'exhibitionDetail',
            component: () => import('@/views/exhibition/detail'),
          }
        ]
      },
      {
        path: '/ttkolclub',
        name: 'ttkolclub',
        component: () => import('@/views/ttkolclub'),
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/logout'),
      },
    ]
  },
  {
    path: '/passport',
    name: 'passport',
    component: () => import('@/views/passport')
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
    const query = {...to.query}
    let isChange = false;
    if(from.query.invite && !to.query.invite) {
        query.invite = from.query.invite;
        isChange = true;
    }
    if(isChange) {
        router.replace({
            name: to.name,
            path: to.path,
            query: query,
        })
        return
    }
    return next();
});


export default router;